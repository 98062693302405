import { IUpdateEntry } from 'asu-sim-toolkit';

export const versionHistory: IUpdateEntry[] = [
  {
    date: new Date('2022-12-23').getTime(),
    title: '1.1.0',
    description: 'Test build',
    changelog: {
      'Core functionality': [
        {
          description: 'All requested features, hopefully',
          type: 'feature',
        },
        {
          description:
            'Merged values in chart are now limited to two decimal places',
          type: 'fix',
        },
      ],
      Visuals: [
        {
          description: 'Precession arc is no longer detached from Earth axis',
          type: 'fix',
        },
      ],
    },
  },
  {
    date: new Date('2023-01-12').getTime(),
    title: '1.2.0',
    description: 'Minor fixes',
    changelog: {
      'Orbit view': [
        {
          description: 'Max and min eccentricity can be set via CAPI.',
          type: 'feature',
        },
        {
          description: 'Precession step can be set via CAPI.',
          type: 'feature',
        },
        {
          description:
            'Mid-points between Earth orbit apsides can now be disabled/enabled via CAPI.',
          type: 'feature',
        },
        {
          description:
            'Insolation readouts (gradient and band) can now be hidden via CAPI.',
          type: 'feature',
        },
      ],
      'Chart view': [
        {
          description: 'Data points are now in correct order.',
          type: 'fix',
        },
        {
          description: 'Time units switched from years to thousands of years.',
          type: 'feature',
        },
        {
          description:
            'Temperature line is now dashed; other parameter lines are solid.',
          type: 'feature',
        },
        {
          description:
            'Y-axis no longer changes when temperature line is not displayed.',
          type: 'fix',
        },
        {
          description:
            'Brush (time slider) indices are now available as CAPI values. Their difference is also available as read-only value.',
          type: 'feature',
        },
      ],
    },
  },
  {
    date: new Date('2023-01-26').getTime(),
    title: '1.3.0',
    description: 'Internal QA (Round I)',
    changelog: {
      'Orbit view': [
        {
          description:
            'Perihelion and aphelion labels are now wrapped in two lines each.',
          type: 'feature',
        },
        {
          description:
            'New CAPI variable: Sim.Orbit.CameraPosition.View ("side", "top", "custom").',
          type: 'feature',
        },
        {
          description:
            'Orbit view is centered regardless of Sim.Orbit.Insolation.Visible',
          type: 'feature',
        },
        {
          description: 'New CAPI variable: Sim.Orbit.Labels.Visible (boolean).',
          type: 'feature',
        },
        {
          description: 'Side & top view buttons now work as intended.',
          type: 'fix',
        },
        {
          description:
            '3D lines are no longer ridiculously huge on Google Pixel phones.',
          type: 'fix',
        },
      ],
      'Chart view': [
        {
          description:
            'Time axis has been adjusted to show "thousands of years ago" (instead of negative years). Time axis value in the tooltip is has been adjusted to match.',
          type: 'feature',
        },
        {
          description:
            'New CAPI variable: Sim.Chart.Multiselect.Enabled (boolean) - allows or disallows displaying more than one of the three orbital parameters at a time.',
          type: 'feature',
        },
        {
          description:
            'Removed parameter readouts from the tooltip if no parameter is selected.',
          type: 'fix',
        },
        {
          description: 'Added temperature unit (°C) to the tooltip.',
          type: 'fix',
        },
        {
          description: 'Chart ticks are back in sync with their grid lines.',
          type: 'fix',
        },
        {
          description:
            'Fixed glitchy Timeline Slider (I swear, this thing gets a new name each time it comes up). Unfortunately, Min and Max CAPI values are now read-only.',
          type: 'fix',
        },
      ],
      Miscellaneous: [
        {
          description:
            'The docs have been updated with information on newly added CAPI variables.',
          type: 'feature',
        },
        {
          description:
            'Fixed scrolling on small mobile screens while in full-screen mode.',
          type: 'fix',
        },
      ],
    },
  },
];
